import React, { useState, useRef } from 'react';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { Headings } from '../../components';
import './Sponsors.css';

// Import images
import partner1Logo from '../../assets/mopd.png';
import partner2Logo from '../../assets/gerb.png';
import partner3Logo from '../../assets/alela.png';
import partner4Logo from '../../assets/akmi0ifC_400x400.jpg';

const Sponsors = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef(null);
  const partnersData = [
    { imgURL: partner1Logo },
    { imgURL: partner2Logo },
    { imgURL: partner3Logo },
    { imgURL: partner4Logo },
    // Add more partners as needed
  ];

  const scrollAmount = 100; // Adjust based on the height of your items

  const handleScroll = (direction) => {
    if (containerRef.current) {
      const scrollHeight = containerRef.current.scrollHeight;
      const clientHeight = containerRef.current.clientHeight;
      
      if (direction === 'up') {
        setScrollPosition((prev) => Math.max(prev - scrollAmount, 0));
      } else {
        setScrollPosition((prev) => Math.min(prev + scrollAmount, scrollHeight - clientHeight));
      }
    }
  };

  return (
    <div id="partners" className="d-block pt-md-4">
      <Headings 
        title="Organizers " 
        text="Our event is brought to you by a dedicated team of passionate organizers who are committed to creating a platform that fosters creativity, collaboration, and community engagement. "
      />
      <div className="partners-wrapper">
        <button className="scroll-btn prev" onClick={() => handleScroll('up')}>
          {/* <FaChevronUp /> */}
        </button>
        <div
          className="partners-container"
          ref={containerRef}
          style={{ transform: `translateY(-${scrollPosition}px)` }}
        >
          {partnersData.map(({ imgURL }, index) => (
            <div className="partner-item" key={index}>
              <img src={imgURL} alt={`Partner ${index + 1}`} className="img-fluid img-partner" />
            </div>
          ))}
        </div>
        <button className="scroll-btn next" onClick={() => handleScroll('down')}>
          {/* <FaChevronDown /> */}
        </button>
      </div>
    </div>
  );
};

export default Sponsors;
