import images from './images';

const Menu = [
    {
        text: 'About Us',
        link: '/AboutUs',
    },
    // {
    //     text: 'Events',
    //     link: '#services',
    // },
    // {
  
    //     text: 'Call For Artists',
    //     link: '#call',
    // },
    // {
    //     text: 'Partners',
    //     link: '#partner',
    // },
    {
        text: 'Previous Event',
        link: '/PreviousEvent',
    },

    {
        text: 'Contest',
        link: '/contest',
    },
    {
        // text: 'Gallary',
        // link: '#',
    },
    {
        text: 'Register',
        link: '/register',
    }
];
const ServicesData = [
    {
        titleone: 'Art ',
        titletwo: 'Exhibition',
        link: '#',
        itemclass: 'blight',
        imgURL: images.services01,
    },
    {
        titleone: 'Art ',
        titletwo: 'Workshops',
        link: '#',
        itemclass: 'bgreen',
        imgURL: images.services02,
    },
    // {
    //     titleone: 'Panel ',
    //     titletwo: 'Discussions',
    //     link: '#',
    //     itemclass: 'bdark',
    //     imgURL: images.services03,
    // },
    // {
    //     titleone: 'Live ',
    //     titletwo: 'Performances',
    //     link: '#',
    //     itemclass: 'blight',
    //     imgURL: images.services04,
    // },
    // {
    //     titleone: 'Awards ',
    //     titletwo: 'Ceremony',
    //     link: '#',
    //     itemclass: 'bgreen',
    //     imgURL: images.services05,
    // },
    // {
    //     titleone: 'Networking ',
    //     titletwo: 'Sessions',
    //     link: '#',
    //     itemclass: 'bdark',
    //     imgURL: images.services06,
    // },
];
const ServicesData2 = [
    {
        titleone: 'Art ',
        titletwo: 'Exhibition',
        link: '#',
        itemclass: 'blight',
        imgURL: images.services01,
    },
    {
        titleone: 'Art ',
        titletwo: 'Workshops',
        link: '#',
        itemclass: 'bgreen',
        imgURL: images.services02,
    },
    {
        titleone: 'Panel ',
        titletwo: 'Discussions',
        link: '#',
        itemclass: 'bdark',
        imgURL: images.services03,
    },
    {
        titleone: 'Live ',
        titletwo: 'Performances',
        link: '#',
        itemclass: 'blight',
        imgURL: images.services04,
    },
    {
        titleone: 'Awards ',
        titletwo: 'Ceremony',
        link: '#',
        itemclass: 'bgreen',
        imgURL: images.services05,
    },
    {
        titleone: 'Networking ',
        titletwo: 'Sessions',
        link: '#',
        itemclass: 'bdark',
        imgURL: images.services06,
    },
]

const CaseStudies = [
    {
        text: 'For a local restaurant, we implemented a targeted PPC campaign that resulted in a 50% increase in website traffic and a 25% increase in sales.',
        link: '#',
    },
    {
        text: 'For a B2B software company, we developed an SEO strategy that resulted in a first page ranking for key keywords and a 200% increase in organic traffic.',
        link: '#',
    },
    {
        text: 'For a national retail chain, we created a social media marketing campaign that increased followers by 25% and generated a 20% increase in online sales.',
        link: '#',
    },
];

const WorkingProcess = [
    {
        title: 'የኪነ-ጥበብ ዓይነቶች',
        description: 'አየር ንብረት ለውጥና አካባቢ ጥበቃ ላይ ያተኮረ :- <br />  ግጥም <br />አጭር ታሪክ/ተረክ <br /> አጭር ፊልም <br /> ዘፈንና ዳንስ <br /> ስዕልና ዲጂታል አርት <br />ፎቶግራፊ ሌሎችም የኪነ-ጥበብ ዘርፎች',
    },
    {
        title: 'ዳኝነት',
        description: 'ውድድሩ የሚዳኘው 60% በተጓዥ ዩትዩብ ቻናል ላይ በሚሰጥ የህዝብ ድምጽ እና 40% ከኪነ-ጥበብ እና አካባቢ ጥበቃ ባለሞያዎች የተዋቀረ የዳኞች ቡድን በሚሰጥ ድምር ውጤት',
    },
    {
        title: 'የመጨረሻ ቀን',
        description: 'መመዝገቢያ፡- መስከረም 5 <br /> ማስረከቢያ፡- መስከረም 10',
    },
    {
        title: 'ከተወዳዳሪዎች የሚጠበቅ',
        description: 'የተጓዥ ኪነ-ጥበባት ዌብሳይት (www.teguadz.com) ላይ መመዝገብ <br /> <br />->መመዝገቢያ ቅጹ ላይ የሚጠየቁ መረጃዎችና ማስረጃዎች በአግባቡ ማስቀመጥ <br /> <br />->የሚወዳደሩበትን የኮፒራይት ጥያቄ የማየነሳበት/የሌለበት የኪነ-ጥበብ ስራ በቪዲዮ ቀርጸው በቴሌግራም መላክ (ወይም እኛ ቀረጸን እንድናዘጋጅልዎ ከፈለጉ ቀጠሮ ማስያዝና ስቱዲዮ መጥቶ መቀረጽ) <br /> <br /> ->የተጓዥ የዩትዩብ ቻናል ሰብስክራይብ ማድረግ <br /> <br />->አሸናፊ ሲሆኑ ለሽልማት ፕሮግራም በአካል መገኘት ወይም ተወካይ መላክ <br /> <br /> ->ለኤግዚቪሽን ወደ አዛርባጃን ለሚደረገው ጉዞ ከእርስዎ የሚጠበቁ ነገሮችን (ፓስፖርት ማውጣት ወይም ማደስ፣ የርስዎን መረጃዎች ለአዘጋጁ ማጋራት) በአግባቡ መፈጸም <br /> <br />->በጉዞ ወቅት ከአዘጋጁና ከፕላንና ልማት ሚኒሰቴር የሚሰጠዎትን አደራ በአግባቡ መወጣት <br /><br /> ->በአዛርባጃን ለሚደረግ ተጨማሪ የባህል ልውውጥ ስራ ራስን ማዘጋጀትና በፕሮገራሙ ላይ የነቃ ተሳትፎ ማድረግ <br /><br /> የመመዝገቢያ ክፍያ :- 600 ',
    },
 
];


const WorkingProcess2 = [
    {
        title: 'የጭብጥ ይዘት',
        description: 'ማንኛውም ተወዳዳሪ በሚሰራው የውድድር የኪነ ጥበብ ስራ ላይ የዘላቂ የአካባቢ ጥበቃና እንክብካቤ ጉዳዮችን ጭብጥ ያካተተ ሆኖ የኢትዮጵያን ከፍ ሲልም የአፍሪካን አካባቢ ጉዳዮች የሚዳስስ ቢሆን ይመከራል ::',
    },
    {
        title: 'አዲስና ፈጠራ የተሞላበት ሥራ',
        description: 'የሚቀርበው የጥበብ ፈጠራ ከዚህ በፊት ያልተሰራና አዲስ ፈጠራ የተሞላበት የአካባቢ ጉዳዮችን የሚያረጠን ጉዳይ ጉዳይ ብያንፀባርቅ ይመረጣል::',
    },
    {
        title: 'የቴክኒክ ችሎታ',
        description: 'በተለያዩ ጥበባዊ ቴክኒኮች እና የቁሳቁስ አጠቃቀም ብቃትን ጨምሮ የስነጥበብ ስራው ቴክኒካል አፈፃፀም ይገመገማል።',
    },
    {
        title: 'የመልዕክት ጥራት',
        description: 'የኪነጥበብ ስራውን የሚያስተላልፈው የመልእክት ጥራት ለተወዳዳሪውና አወዳደሪዎቹ ተደራሽነቱ ወሳኝነት አለው:: ',
    },
    {
        title: 'ተጽዕኖ',
        description: 'ከአካባቢ ጥበቃና ዘላቂ ልማትን የሚያበረታቱ ለሌላ ስራ የሚያነቃቁ ስራዎችን ሌሎች ላይ ተፅዕኖ ማምጣት የሚችል የጥበብ ስራዎች ተመራጮች ናቸው ::የስነጥበብ ስራው ግንዛቤን በማሳደግ እና በአካባቢ ጥበቃ ላይ አወንታዊ ተግባራትን በማነሳሳት ላይ ያለው ተጽእኖ በተለይ በዳኞች ቡድን ይገመገማል።  ',
    },
    {
        title: 'ባህላችንን የሚወክሉ ቢሆኑ',
        description: 'ለውድድር የሚቀርቡት የኪነ ጥበብ ስራዎች የኢትዮጵያን አሊያም የአፍሪካን አኩሪ የጋራ ባህሎችና ባህላዊ ቅርሶቻችንን የማንነት መገለጫ ጉዳዮችን ያካተቱ የአከባቢ ብዙሃነትን የሚያንፀባርቁ ስራዎች ይበረታሉ :: ',
    },
    {
        title: 'አካባቢ ግንዛቤ',
        description: 'ኢትዮጵያ ወይም አፍሪካ አሁን አሁን ላይ እየገጠሙት ያሉትን አካባቢያዊ ተግዳሮቶች የሚያንፀባርቁ የኪነጥበብ ስራዎችን ሆነው ለቀጣዩ ትውልድ ተስፋ እና የተነሳሽነትን መንፈስን የሚፈጥሩ ስራዎች ቢሆኑ ይመከራል::  ',
    },
    {
        title: 'አቀራረብ',
        description: 'የሚቀርቡት የኪነጥበብ ስራዎች ላይ በሃሳብ በዝግጅት ባወቃቀር ውበትና ጥራት ጉዳዮች ግንባር ቀደም መስፈርቶች እንደሆኑ ሊታወቅ ይገባል ::',
    },
    {
        title: 'ስነ-ምግባርን በተመለከተ',
        description: 'በምንም አይነት መልኩ ክብረ ነክ የሆነ የኪነ ጥበብ ሥራ በዚህ ውድድር ተቀባይነት የለውም :: እንዲሁም ማግለልን  አወዛጋቢ የሆነ ይዘት የያዙ ስራዎችን የማንቀበል መሆኑን ሊታወቅ ይገባል :: ባንፃሩ አካታችነትን ኛ ብዝሃነትን የባህል ልዩነቶችን የምያንፀባርቁ የአካባቢ ጥበቃና እንክብካቤ ይዘት ያላቸው ስራዎች ተመራጭ ይሆናሉ:: ',
    },
    {
        title: 'የማስረከቢያ መመሪያዎች',
        description: 'ተወዳዳሪዎች የተገለጹትን የማስረከቢያ እና ሌሎች በአዘጋጆቹ ቡድን የተሰጡ መመሪያዎችን ማክበር አለባቸው። የማስረከቢያ መመሪያዎችን አለማክበር የተወዳዳሪውን ሥራ ውድቅ ወይም ከነ አካቴው ሊያሰርዝ  ይችላል።',
    },
   
   
];

const Team = [
    {
        name: 'Yared Gebre',
        position: 'Painter',
        info: 'Participating with a series of paintings that explore the effects of climate change on natural landscapes. With over 10 years of artistic experience, Yared uses vibrant colors to depict environmental transformations and advocate for sustainability.',
        foto: images.team01,
        linkedin: '#',
    },
    {
        name: 'Desta Girma',
        position: 'Singer-Songwriter',
        info: 'Entering the contest with original compositions that address environmental issues through music. With 7+ years in the industry, Desta’s songs blend traditional and modern elements to raise awareness and inspire action for a sustainable future.',
        foto: images.team02,
        linkedin: '#',
    },
    {
        name: 'Mekonnen Tadese',
        position: 'Photographer',
        info: 'Showcasing a collection of photographs that capture the impact of pollution and habitat destruction. With over 5 years of experience, Mekonnen’s work highlights critical environmental issues and promotes conservation through striking visual storytelling.',
        foto: images.team03,
        linkedin: '#',
    },
    // {
    //     name: 'Emily Johnson',
    //     position: 'PPC Manager',
    //     info: '3+ years of experience in paid search advertising. Skilled in campaign management and performance analysis',
    //     foto: images.team04,
    //     linkedin: '#',
    // },
    // {
    //     name: 'Brian Williams',
    //     position: 'Social Media Specialist',
    //     info: '4+ years of experience in social media marketing. Proficient in creating and scheduling content, analyzing metrics, and building engagement',
    //     foto: images.team05,
    //     linkedin: '#',
    // },
    // {
    //     name: 'Sarah Kim',
    //     position: 'Content Creator',
    //     info: '2+ years of experience in writing and editing Skilled in creating compelling, SEO-optimized content for various industries',
    //     foto: images.team06,
    //     linkedin: '#',
    // },

];

const Testimonials = [
    {
        name: 'John Smith',
        position: 'Marketing Director at XYZ Corp',
        testimonial: '"We have been working with Positivus for the past year and have seen a significant increase in website traffic and leads as a result of their efforts. The team is professional, responsive, and truly cares about the success of our business. We highly recommend Positivus to any company looking to grow their online presence."',
    },
    {
        name: 'John Smith',
        position: 'Marketing Director at XYZ Corp',
        testimonial: '"We have been working with Positivus for the past year and have seen a significant increase in website traffic and leads as a result of their efforts. The team is professional, responsive, and truly cares about the success of our business. We highly recommend Positivus to any company looking to grow their online presence."',
    },
    {
        name: 'John Smith',
        position: 'Marketing Director at XYZ Corp',
        testimonial: '"We have been working with Positivus for the past year and have seen a significant increase in website traffic and leads as a result of their efforts. The team is professional, responsive, and truly cares about the success of our business. We highly recommend Positivus to any company looking to grow their online presence."',
    },
    {
        name: 'John Smith',
        position: 'Marketing Director at XYZ Corp',
        testimonial: '"We have been working with Positivus for the past year and have seen a significant increase in website traffic and leads as a result of their efforts. The team is professional, responsive, and truly cares about the success of our business. We highly recommend Positivus to any company looking to grow their online presence."',
    },
];


   const PartnersData = [
      {
   
        imgURL: '../assets/epa.png', // Update the path to your logo images
      },
      {
    
        imgURL: '/path/to/partner2-logo.png', // Update the path to your logo images
      },
      {
    
        imgURL: '/path/to/partner3-logo.png', // Update the path to your logo images
      },
      // Add more partners as needed
    ];
  
  


export default { Menu, CaseStudies, WorkingProcess,WorkingProcess2, Team, Testimonials, ServicesData,ServicesData2, PartnersData };

