import 'bootstrap/dist/css/bootstrap.min.css';
import { Testimonials, CaseStudies, Partners, Contact, Footer, Hero, Process, Services, Team, CTA, Sponsors, Footer1 } from '../container';
import { Menu } from '../components';
import { useNavigate } from 'react-router-dom';
import "./Home.css";
import { FaTelegramPlane } from 'react-icons/fa';
import bannerImage from '../assets/banner2.jpg';

const Home = () => {
  const navigate = useNavigate();

  const handleRegisterClick = () => {
    navigate('/register');
  };

  const handleTelegramClick = () => {
    window.location.href = 'https://t.me/+251975108685'; // Replace with your actual Telegram link
  };

  return (
    <div className="container">
      <Menu />
      <Hero />
      <div>
        <div className="row align-items-center">
          <div className="col-md-6 col-12">
            <div className="video-container">
            <img src={bannerImage} alt="Banner" className="img-fluid" />
            </div>
          </div>
          <div className="col-md-6 col-12">
            <h3>Whispers of Earth Arts Competition & Exhibition</h3>
            <p className="py-4">Win a trip to Baku, Azerbaijan for COP29 or up to 100,000 ETB cash prize</p>
            <button className="btn-positivus" onClick={handleRegisterClick}>Register</button>
          </div>
        </div>
      </div>
      {/* <Testimonials /> */}
      {/* <CTA /> */}
      <Services />
      <Partners />
      <Contact />
      <Footer1/>

      {/* Floating Telegram Button */}
      <button className="telegram-float" onClick={handleTelegramClick}>
        <FaTelegramPlane />
      </button>
    </div>
  );
};

export default Home;
