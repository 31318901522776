import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { data } from '../../constants';
import { Headings } from '../../components';
import './Process.css';

const Process = () => {
  return (
    <div className="section-padding" id="process">
      <Headings title="የምድር ሹክሹክታ የኪነ-ጥበብ ውድድር" text="" />

      <Accordion>
        {data.WorkingProcess.map((item, index) => (
          <Accordion.Item eventKey={index} key={index}>
            <Accordion.Header>
              <div>
                <span>{index + 1}</span> {item.title}
              </div>
            </Accordion.Header>
            <Accordion.Body>
              {/* Using dangerouslySetInnerHTML to correctly render HTML line breaks */}
              <p dangerouslySetInnerHTML={{ __html: item.description }} />
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

export default Process;
