import React from 'react';
import './Footer.css';
import { images, data } from '../../constants';
import { BsFacebook, BsTwitter, BsLinkedin, BsInstagram, BsYoutube } from 'react-icons/bs';

const Footer1 = () => {
  return (
    <footer>
      <div className="row align-items-center">
        {/* <div className="col-md-2 col-12">
          <img src={images.logofooter} alt="logo" className="img-fluid" />
        </div> */}
        <div className="col-md-8 col-12">
          <ul className="navbar-footer">
            {data.Menu.slice(0, -1).map((item, index) => (
              <li key={index}> 
                <a href={item.href || "/"}>{item.text}</a>
              </li>
            ))}
          </ul>
        </div>
        <div className="col-md-2 col-12 text-center text-md-end fs-4">
          <div 
            role="button" 
            tabIndex={0} 
            onClick={() => window.open('https://www.youtube.com/watch?v=IG2ZLPmmmJU', '_blank', 'noopener,noreferrer')}
            onKeyPress={(e) => e.key === 'Enter' && window.open('https://www.youtube.com/watch?v=IG2ZLPmmmJU', '_blank', 'noopener,noreferrer')}
            style={{ cursor: 'pointer', display: 'inline-block', marginRight: '10px' }}
          >
            <BsYoutube />
          </div>
          <div 
            role="button" 
            tabIndex={0} 
            onClick={() => window.open('https://www.facebook.com/Teguadz/', '_blank', 'noopener,noreferrer')}
            onKeyPress={(e) => e.key === 'Enter' && window.open('https://www.facebook.com/Teguadz/', '_blank', 'noopener,noreferrer')}
            style={{ cursor: 'pointer', display: 'inline-block' }}
          >
            <BsFacebook />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 col-12">
          <div className="info-container">
            <ul className="info-contact">
              <li><span>Contact us:</span></li>
              <li>Email: <a href="mailto:info@teguadz.com">info@teguadz.com</a></li>
              <li>Phone: <a href="tel:+251923982992">+251923982992</a></li>
              <li>or <a href="tel:+251909294042">+251909294042</a></li>
              <li><a href="https://maps.app.goo.gl/PxstNhCJpPsvxT1b7" target="_blank" rel="noopener noreferrer">Address: Yemeru Senay Building 7th Floor</a></li>
            </ul>
          </div>
        </div>

        <div className="rights">
          <p>© 2024 Teguadz. All Rights Reserved.</p>
          <p><a href="#" alt="Privacy Policy">Privacy Policy</a></p>
        </div>
      </div>
    </footer>
  );
}

export default Footer1;
