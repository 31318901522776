import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Registration from './pages/Registration';
import Home from './pages/Home';
import Checkout from './pages/Checkout';
import AdminLogin from './pages/AdminLogin';
import AdminDashboard from './pages/AdminDashboard';
import AboutUs from './pages/AboutUs';
import PreviousEvent from './pages/PreviousEvent';
import Contest from './pages/Contest';

const App = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/AboutUs" element={<AboutUs /> } />
    <Route path="/PreviousEvent" element={<PreviousEvent />} />
    <Route path="/contest" element={<Contest />} />
    <Route path="/register" element={<Registration />} />
    <Route path="/checkout" element={<Checkout />} />
    <Route path="/admin/login" element={<AdminLogin />} />
    <Route path="/admin/dashboard" element={<AdminDashboard />}/>
  </Routes>
);

export default App;
