import React from 'react';
import { FaPencilAlt, FaAngellist, FaPaperPlane, FaTree, FaUsers, FaGavel, FaPalette, FaHandsHelping } from 'react-icons/fa';
import './AboutUs.css';
import { Menu } from '../components';
import banner from '../assets/banner.jpg';
import { Footer } from '../container';

const iconMap = {
  'Books': <FaTree />,
  'TV SHOW': <FaPalette />,
  'National Engagement': <FaUsers />,
  // 'Books': <FaGavel />,
  // 'Books': <FaHandsHelping />,
  'SOCIAL MEDIA PRESENCE': <FaUsers />,
};

const contentBoxes = [
  {
    title: 'TV SHOW',
    description: 'Teguadz Arts works in close collaboration PRESENCEwith media partners to ensure that the event is transmitted regularly on TV shows. This ensures maximum visibility for all performers, sponsors, and partners involved in the event.',
  },
  {
    title: 'SOCIAL MEDIA PRESENCE',
    description: 'Yes, Teguadz Arts recognizes the importance of social media in reaching a wider audience andpromoting the event. As such, the contents andpromotions of the event will be strongly visible onvarious social media platforms such as Facebook,Instagram, Twitter, and YouTube.',
  },
  {
    title: 'Books',
    description: 'Teguadz Arts recognizes the importance of preserving and promoting the works of talented writers who perform on its stages. As such, the platform has decided to publish a collection of selected poetry,prose, and short story works annually.',
  },

];

const AboutUs = () => {
  return (
    <>
      <Menu />
      <section className="section_all bg-light" id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section_title_all text-center">
              
                <div className="">
                  <i className=""></i>
                </div>
              </div>
            </div>
          </div>

          <div className="row vertical_content_manage mt-5">
            <div className="col-lg-6">
              <div className="about_header_main mt-3">
                <div className="about_icon_box">
                  <h1 className="text_custom font-weight-bold">About Teguadz Arts</h1>
                </div>
                <h4 className="about_heading text-capitalize font-weight-bold mt-4">
            
                </h4>
                <p className="text-muted mt-3">
                Teguadz Arts is a hybrid artistry session that
was launched on May 07, 2022, and is regularly
organized by alela events & multimedia. This
platform is open to all types of performing arts,
including poetry, music, comedy, prose, and
more. As Teguadz Arts travels across the
country and beyond, it aims to discover and
explore talents, genres, types, and techniques
from different unique backgrounds  With a team of actors and partners, Teguadz
Arts seeks to bring together diverse
backgrounds and capitalize on both the
differences and similarities between them. This
platform offers one of the biggest opportunities
for artists to showcase their skills and creativity
in a supportive and inclusive environment.

                </p>
                {/* <p className="text-muted mt-3">
                 
                </p> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img_about mt-3">
                <img src={banner} alt="About Us" className="img-fluid mx-auto d-block" />
              </div>
            </div>
          </div>

          <div className="row mt-3">
            {contentBoxes.map((content, index) => (
              <div className="col-lg-4" key={index}>
                <div className="about_content_box_all mt-3">
                  <div className="about_detail text-center">
                    <div className="about_icon">
                      {iconMap[content.title]}
                    </div>
                    <h5 className="text-dark text-capitalize mt-3 font-weight-bold">{content.title}</h5>
                    <p className="edu_desc mt-3 mb-0 text-muted">{content.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default AboutUs;
