import React from 'react';
import { useNavigate } from 'react-router-dom';
import { images } from '../../constants';
import banner from '../../assets/logoo.png';
import { IconScroll } from '../../components';
import './Hero.css';

const logos = ["logo01", "logo02", "logo03", "logo04", "logo05", "logo06"];

const Hero = () => {
  const navigate = useNavigate();

  const handleRegisterClick = () => {
    navigate('/register');
  };

  return (
    <div className="hero">
      <div className="row align-items-center">
        <div className="col-md-6 col-12">
          <h1 className="title">Teguadz Arts </h1>
          <p className="py-4">
            Teguadz Arts celebrates the vibrant fusion of Ethiopian culture and contemporary creativity. We empower artists by providing a platform to showcase their talents and connect with a global audience.
          </p>
          {/* <button className="btn-positivus" onClick={handleRegisterClick}>Register</button> */}
        </div>
        <div className="col-md-6 col-12 mt-md-0 mt-5">
          <img className="img-fluid" src={banner} alt="design" />
        </div>
      </div>

      {/* Uncomment if needed */}
      {/* <div className="clients">
        {logos.map((logo, index) => (
          <img
            key={index}
            src={images[logo]}
            alt={images[logo]}
          />
        ))}
      </div> */}

    </div>
  );
};

export default Hero;
