import React from 'react';
import ph1 from '../assets/ph1.jpg';
import ph2 from '../assets/ph2.jpg';
import ph3 from '../assets/ph3.jpg';
import ph4 from '../assets/ph4.jpg';
import ph5 from '../assets/ph5.jpg';
import ph6 from '../assets/ph6.jpg';
import ph7 from '../assets/ph7.jpg';
import ph8 from '../assets/ph8.jpg';
import ph9 from '../assets/ph9.jpg';
import ph10 from '../assets/ph10.jpg';
import './PreviousEvent.css';

import { Menu } from '../components';

const PreviousEvent = () => {
  const imagesRow1 = [ph1, ph2, ph3];
  const imagesRow2 = [ph2, ph5, ph6];
  const imagesRow3 = [ph7, ph8, ph9];
  const imagesRow4 = [ph10, ph1, ph4];

  return (
    <div className='container'>
      <Menu />
      <section>
        <div className='previous-event-header'>
          <h2>Previous Teguadz Events <br className="sm:block hidden" /></h2>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12 mb-4 mb-lg-0">
            <img
              src={imagesRow1[0]}
              className="w-100 shadow-1-strong rounded mb-4"
              alt='Event Image'
            />
            <img
              src={imagesRow2[0]}
              className="w-100 shadow-1-strong rounded mb-4"
              alt='Event Image'
            />
            <img
              src={imagesRow3[0]}
              className="w-100 shadow-1-strong rounded mb-4"
              alt='Event Image'
            />
            <img
              src={imagesRow4[0]}
              className="w-100 shadow-1-strong rounded mb-4"
              alt='Event Image'
            />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mb-4 mb-lg-0">
            <img
              src={imagesRow2[1]}
              className="w-100 shadow-1-strong rounded mb-4"
              alt='Event Image'
            />
            <img
              src={imagesRow3[1]}
              className="w-100 shadow-1-strong rounded mb-4"
              alt='Event Image'
            />
            <img
              src={imagesRow1[1]}
              className="w-100 shadow-1-strong rounded mb-4"
              alt='Event Image'
            />
            <img
              src={imagesRow4[1]}
              className="w-100 shadow-1-strong rounded mb-4"
              alt='Event Image'
            />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mb-4 mb-lg-0">
            <img
              src={imagesRow3[2]}
              className="w-100 shadow-1-strong rounded mb-4"
              alt='Event Image'
            />
            <img
              src={imagesRow1[2]}
              className="w-100 shadow-1-strong rounded mb-4"
              alt='Event Image'
            />
            <img
              src={imagesRow2[2]}
              className="w-100 shadow-1-strong rounded mb-4"
              alt='Event Image'
            />
            <img
              src={imagesRow4[2]}
              className="w-100 shadow-1-strong rounded mb-4"
              alt='Event Image'
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default PreviousEvent;
