import React, { useEffect, useState } from 'react';
import './AdminDashboard.css';

const AdminDashboard = () => {
  const [registrations, setRegistrations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchRegistrations = async () => {
      try {
        const response = await fetch('https://teguadz-final.onrender.com/api/registrations', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('adminToken')}`, // Assuming token is stored in localStorage
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch registrations');
        }
        const data = await response.json();
        setRegistrations(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchRegistrations();
  }, []);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredRegistrations = registrations.filter((registration) =>
    registration.firstName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="admin-dashboard">
      <h1>Registered Users</h1>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search by first name"
          value={searchQuery}
          onChange={handleSearchChange}
          className="search-input"
        />
      </div>
      <table>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Phone Number</th>
            <th>Email</th>
            <th>City</th>
            <th>Current Address</th>
            <th>Art Form</th>
          </tr>
        </thead>
        <tbody>
          {filteredRegistrations.map((registration) => (
            <tr key={registration._id}>
              <td>{registration.firstName}</td>
              <td>{registration.lastName}</td>
              <td>{registration.phoneNumber}</td>
              <td>{registration.email}</td>
              <td>{registration.city}</td>
              <td>{registration.currentAddress}</td>
              <td>{registration.artForm}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminDashboard;
