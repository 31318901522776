import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Registration.css';
import { Menu } from '../components';

const Registration = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    city: '',
    currentAddress: '',
    artForm: '',
    otherArtForm: ''
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'artForm' && value !== 'Other') {
      // Clear 'otherArtForm' when a non-'Other' art form is selected
      setFormData({ ...formData, artForm: value, otherArtForm: '' });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://teguadz-final.onrender.com/api/registrations', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          artForm: formData.artForm === 'Other' ? formData.otherArtForm : formData.artForm
        }),
      });
      if (response.ok) {
        alert('Registration successful!');
        setFormData({
          firstName: '',
          lastName: '',
          phoneNumber: '',
          email: '',
          city: '',
          currentAddress: '',
          artForm: '',
          otherArtForm: ''
        });
        navigate('/checkout');
      } else {
        const result = await response.json();
        throw new Error(result.message || 'Something went wrong.');
      }
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div>
      <Menu />
    <div className="registration-container">

      <h2>Register</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>First Name</label>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Last Name</label>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Phone Number</label>
          <input
            type="text"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>City</label>
          <input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Current Address</label>
          <input
            type="text"
            name="currentAddress"
            value={formData.currentAddress}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Art Form</label>
          <select
            name="artForm"
            value={formData.artForm}
            onChange={handleChange}
            required
          >
            <option value="">Select Art Form</option>
            <option value="Poetry">Poetry</option>
            <option value="Short Story and Essay">Short Story and Essay</option>
            <option value="Short Film">Short Film</option>
            <option value="Music and Dance">Music and Dance</option>
            <option value="Painting and Digital Art">Painting and Digital Art</option>
            <option value="Other">Other</option>
          </select>
        </div>
        {formData.artForm === 'Other' && (
          <div className="form-group">
            <label>Other Art Form</label>
            <input
              type="text"
              name="otherArtForm"
              value={formData.otherArtForm}
              onChange={handleChange}
              placeholder="Please specify your art form"
              required
            />
          </div>
        )}
        <button type="submit" className="btn">Register</button>
      </form>
    </div>
</div>

  );
};

export default Registration;
