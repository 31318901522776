import React, { useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Headings } from '../../components';
import './Partners.css';

// Import images
import partner1Logo from '../../assets/mopd.png';
import partner2Logo from '../../assets/gerb.png';
import partner3Logo from '../../assets/alela.png';
import partner4Logo from '../../assets/akmi0ifC_400x400.jpg';

// Add more imports as needed

const Partners = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = (direction) => {
    const container = document.querySelector('.partners-container');
    const scrollAmount = container.offsetWidth / 2; // Adjust as needed
    if (direction === 'left') {
      setScrollPosition((prev) => Math.max(prev - scrollAmount, 0));
    } else {
      setScrollPosition((prev) => Math.min(prev + scrollAmount, container.scrollWidth - container.clientWidth));
    }
    container.scrollTo({
      left: scrollPosition,
      behavior: 'smooth',
    });
  };

  // Define partners data with imported images
  const partnersData = [
    { imgURL: partner1Logo },
    { imgURL: partner2Logo },
    { imgURL: partner3Logo },
    { imgURL: partner4Logo },
 
    // Add more partners as needed
  ];

  return (
    <div id="partners" className="d-block pt-md-4">
         <Headings 
  title="Our Partners" 
  text="We're proud to partner with leading organizations that support and enhance our mission. "
/>
 


      <div className="partners-wrapper">
        {/* <button className="scroll-btn prev" onClick={() => handleScroll('left')}>
          <FaChevronLeft />
        </button> */}
        <div className="partners-container">
          {partnersData.map(({ imgURL }, index) => (
            <div className="partner-item" key={index}>
              <img src={imgURL} alt={`Partner ${index + 1}`} className="img-fluid img-partner" />
            </div>
          ))}
        </div>
        {/* <button className="scroll-btn next" onClick={() => handleScroll('right')}>
          <FaChevronRight />
        </button> */}
      </div>
    </div>
  );
};

export default Partners;
