import React from 'react';
import { useNavigate } from 'react-router-dom';
import { images } from '../constants';
import banner from '../assets/logoo2.png';
import { IconScroll, Menu } from '../components';
import './Contest.css';
import { Footer, Process,Process2, Services2, Sponsors } from '../container';
import CompetitionInfo from './Competitioninfo';

const logos = ["alela", "mopd", "gerb"];

const Contest = () => {
  const navigate = useNavigate();

  const handleRegisterClick = () => {
    navigate('/register');
  };

  return (
    <div>
    <div className="container2">
    <Menu />
    <div className="hero">
      <div className="row align-items-center">
     
        <div className="col-md-6 col-12">
          <h1 className="title">Whispers of Earth Arts Competition & Exhibition</h1>
          <p className="py-4">Win a trip to Baku, Azerbaijan for COP29 or up to 100,000 ETB cash prize</p>
          <button className="btn-positivus" onClick={handleRegisterClick}>Register</button>
          
        </div>
 
         
   
                <div className="col-md-6 col-12 mt-md-0 mt-5">
          <img className="img-fluid" src={banner} alt="design" />
        </div>
      </div>

      {/* Uncomment if needed */}
      {/* <div className="clients">
        {logos.map((logo, index) => (
          <img
            key={index}
            src={images[logo]}
            alt={images[logo]}
          />
        ))}
      </div> */}
      {/* <IconScroll /> */}

    </div>
    <Services2 />

    {/* <CompetitionInfo /> */}
    <Process />
    <Process2/>
    <Sponsors />
    <Footer />

    </div>
    </div>
  );
};

export default Contest;
 
    
    
    
    
    
    
    
    
    
    
    
